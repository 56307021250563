const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Velkommen til første modul af hygiejnetræningen. Jeg er din holdleder og hjælper dig gennem spillet.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-what-is-it-about',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Træningen handler om, hvordan vi sikrer høj fødevarsikkerhed og høj kvalitet. For det starter nemlig med dig. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 '],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 '],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-three-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 '],
		text: {
			da: `Du skal have tre stjerner for at gå videre til det næste modul. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-right-clothing',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 '],
		text: {
			da: `Første opgave handler om den rigtige påklædning. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-face-mask',
		layout: 's1-m1-face-mask',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde? Tryk på de rigtige billeder.`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Billede af alt hår og ører dækket af hårnet. Ingen skæg. 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Billede af alt hår, ører og alt skæg dækket. 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager pose-1',
							text: {
								da: `Hvis du ikke er nybarberet inden for de sidste 24 timer, så skal du bære skægbind. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Noget hår stikker ud. Intet skæg. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Alt hår dækket. Skæg. Ingen skægbind. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Alt hår men ikke ører dækket. Intet skæg. 
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		subtype: 'images',
		background: 'dressing-room',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Briller med smykkesten
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er på grund af smykkestenene. Du kan dække stenene med et blåt, detekterbart plaster, og så er det okay. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Næsepiercing
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Armbåndsur
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Halskæde
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Glat vielsesring uden sten
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-medicinal-jewelry',
		background: 'dressing-room',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Hvis du har såkaldt medicinske smykker, så må du selvfølgelig gerne have dem på, hvis de er godkendt af din nærmeste leder.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-good-hygiene',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Her er der et skab til privat tøj, og et sted du henter arbejdstøj. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room-clothes',
		layout: 's1-m1-dressing-room-clothes',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 4 ting som er forkerte på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'clothes' // Arbejdstøj i skab
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'food' // Mad på bænk el. lign.
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet', // Hårnet i skab
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig, det skal bare smides ud. `,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'clothes-floor' // Arbejdstøj på gulv
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-work-clothes-private-clothes',
		background: 'dressing-room',
		text: {
			da: `Dit arbejdstøj skal ikke lægges i eller ved skabet til privat tøj. Den eneste undtagelse er hvis du skal ryge - der skal du nemlig skifte til privat tøj. Hvor skal du så opbevare dit arbejdstøj imens?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Inde i skabet`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `I en kurv ved skabet (Avedøre) eller på en krog på skabet (Pandrup). `,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `På bænken`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `I rummet med arbejdstøj`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-clean-up',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Husk at du selv har ansvar for at rydde op og at gøre rent i dine skabe og lægge tøj til vask. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-ready-for-production',
		background: 'dressing-room',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Godt, så er vi vist ved at være klar til at gå ud i produktionen. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-work-what-to-remember',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `På vej ind i produktionen skal du igennem sålevaskeren. Hvad er vigtigt at huske her?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `At gøre det inden håndvask`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `At have bare tæer`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `At gå baglæns`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej. Det er en rigtigt dårlig idé i forhold til sikkerhed. `,
								en: ``,
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-sole-hands',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Du skal altid vaske hænder, inden du går ind i produktionen. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: ``,
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder med engangshåndklæde`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid engangshåndklæde i skraldespand`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Brug eventuelt beskyttende creme`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					da: `Brug håndsprit, hvis det er til rådighed`,
					en: ``,
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Håndhygiejne handler også om neglene. 
				<br /><br />Tryk på det billede, som er korrekt.`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Korte og rene negle
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // korte med neglelak
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Korte let beskidte negle
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Lange let beskidte negle
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Tydeligt kunstig negl
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-sole-hands',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Hvis du har kunstige negle skal du bruge handsker. Hvor ofte skal du som minimum huske at skifte dem?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hver fjerde time `,
					en: ``,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du skal selvfølgelig også skifte dem, hvis de bliver beskidte, eller hvis der går hul i dem. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Hver time`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hver anden time`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Bare næste dag`,
					en: ``,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hand-washing',
		layout: 's1-m1-hand-washing',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Der er især tre områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Håndfladen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Håndryggen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Neglene (hvis en negl vælges, så marker alle)
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Håndled (hvis et håndled vælges, så marker begge)
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Mellem fingre (hvis mellem et sæt fingrer vælges, så marker alle)
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-which-situations-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?
				<br /><br />
				<b>Vælg 1 - og husk at læse alle mulighederne:</b>`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du går ind i produktionen`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Når du har samlet noget op fra gulvet`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Når du har rørt ved din mund og næse`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Når du har håndteret affald`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `I alle ovenstående situationer `,
					en: ``,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis du står et sted hvor der ikke er adgang til håndvask - for eksempel i rugbrødspakkeriet - er det okay at afspritte hænder.`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-wounds',
		layout: 's1-m1-wounds',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Hvis du har sår eller rifter, hvad skal du så bruge?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Blåt plaster og blå handske
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja. Det blå plaster er detekterbart. Og husk at skifte handske mindst hver fjerde time, eller hvis de er beskidte, eller der går hul i dem.`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Blåt plaster
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Almindeligt plaster
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Gips
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room-problematic-clothes',
		layout: 's1-m1-dressing-room-problematic-clothes',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Husk også at tjekke dit arbejdstøj. Se om du kan spotte de 2 problemer her. Klik OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet' // Hårnet sidder ikke ordentligt
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'crumbs' // Krummer på arbejdstøj
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-before-production',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Inden vi går ind i produktionen skal vi nå to ting til: allergener og sygdom. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-allergens',
		layout: 's1-m1-allergens',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Allergerner kan gøre forbrugerne syge, hvis de kommer i et produkt, hvor de ikke skal være. Klik på det allergen, du skal være særligt opmærksom på.`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Orange
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Gluten
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Jordbær
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Peanuts
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Selv små mængder peanuts kan være farlige for allergikere, så skift tøj hvis du for eksempel har spildt under frokosten.`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Ananas
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-yellow-tools',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `I Avedøre bruger vi mælk i nogle produkter, men ikke i andre. Derfor skal der laves ekstra rengøring efter produktion med mælk. Der skal du altid bruge GULE redskaber.`,
			en: ``,
		}
	}),


	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-sickness',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		text: {
			da: `Hvis du har vært syg, er der nogle ting, som du skal være særligt opmærksom på inden du genoptager arbejdet. Hvad er det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, text: `At jeg ikke kan smitte mine kolleger`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `At pudse næse mindst fire gange i timen`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `At bruge handsker på toilettet`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, text: `At der ikke er risiko for at overføre smitte til produkterne`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true, text: `At jeg skal tale med nærmeste leder, hvis jeg er i tvivl om, hvilke opgaver jeg må udføre`
			}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-done-first-module',
		background: 'dressing-room',
		backgroundModifier: 'sole-washer',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Sådan, du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage modulet en gang til. `,
			en: ``,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};