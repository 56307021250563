const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om hygiejne i selve produktionen.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-different-procedures',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Her skal det især handle om de forskellige procedurer, som skal følges. Men også om, hvad du gør, hvis der er fejl eller afvigelser. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-the-goal',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			da: `Målet er stadig at levere højst mulige fødevaresikkerhed og kvalitet til vores kunder. Husk, du skal have tre stjerner for at gennemføre modulet. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-when-washing-hands',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `For at sikre høj fødevaresikkerhed`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Af alle ovenstående grunde`,
					en: ``,
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-closer-look-production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-belt',
		layout: 's1-m2-production-belt',
		background: 'production',
		backgroundModifier: 'no-machines',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'open-box' // Åben kasse med produkt direkte på gulv
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'yellow-tape' // Noget på båndet er repareret med flappende gul tape
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'yellow-tool', // Gult værktøj ligger på bånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tape' // Gaffatape på maskinen
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'frayed-belt' // Flossede bånd
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-footwear',
		layout: 's1-m2-footwear',
		subtype: 'images',
		text: {
			da: `Hvis du af en eller anden grund bliver nødt til at gå op på et bånd, hvad skal du så bruge?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // blåt skoovertræk
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Stylter
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Bare tæer
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Tape om skoen
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-every-day-production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			da: `Du er tæt på produktionen hver dag, og derfor lægger du garanteret mærke til ting, som ikke er hensigtsmæssige, eller som andre overser. Hvis du ser noget, der kan give problemer, så gør noget ved det, eller sig det til nærmeste leder. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-contamination',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-ruined-lamp',
		text: {
			da: `En lampe er gået i stykker. Der er risiko for der er kommet plast i produkterne. Hvad skal du gøre? Sæt handlingerne i den rigtige rækkefølge. Tryk så OK. `,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Tilkald nærmeste leder`,
					en: ``,
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Følg procedure på skema`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Udfyld skema`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Afvent frigivelse fra leder`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					da: `Fortsæt arbejdet`,
					en: ``,
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-our-products-contamination',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det kan være farligt`,
					en: ``,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det kan ødelægge smagen`,
					en: ``,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: ``,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det udleder mere CO2`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Forurening er kun et problem, hvis Kvalitet ser det`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Det er ulovligt`,
					en: ``,
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-good-hygiene',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `God hygiejne og dermed høj fødevaresikkerhed og kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-bread-production-issues',
		layout: 's1-m2-bread-production-issues',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'person', // Person har clipboard med STOR clip.
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Clips må aldrig medtages i produktionen. `,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'soap' // Sæbeskum på bånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'paint', // Røremaskine med afskallende maling
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set! Maling der skaller af kan havne i vores produkter, og det går ikke.`,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'puddle' // Vand på gulvet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-last-assignment-water-on-floor',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `I sidste opgave var det et problem, at der var vand på gulvet. Hvorfor?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Man kan glide i det`,
					en: ``,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Oprydning er også vigtigt af hensyn til sikkerheden. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Skadedyr kan godt lide fugt`,
					en: ``,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Maskinerne kan ikke tåle vand`,
					en: ``,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Brødet kan blive vådt`,
					en: ``,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Brødet skal ikke ned på gulvet, så det burde ikke være et problem. `,
								en: ``,
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-pests',
		background: 'production',
		backgroundModifier: 'moved-right',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Skadedyr kan være et stort problem i forhold til hygiejne.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-mouse',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Give den en skive brød`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Prøve at fange den`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til nærmeste leder`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Og det gælder også andre dyr som fluer og møl.`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Hente en kat`,
					en: ``
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-nearest-leader',
		background: 'production',
		backgroundModifier: 'moved-right',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Du skal også tage fat i nærmeste leder, hvis du ser spor efter skadedyr. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-moth-sign',
		layout: 's1-m2-moth-sign',
		subtype: 'images',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `I hvilken af følgende sække med råvarer er der tegn på møl?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-stop-pests',
		background: 'production',
		backgroundModifier: 'moved-right',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			da: `For at forhindre skadedyr, så har vi for eksempel mølfangere og limfælder. Det er vigtigt de får lov at blive stående. Hvis de står uhensigtsmæssigt, så kontakt din nærmeste leder. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-still-few-assignments',
		background: 'production',
		backgroundModifier: 'moved-right',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Vi mangler lige et par opgaver, og så er du næsten færdig. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-go-outside',
		layout: 's1-m2-go-outside',
		subtype: 'images',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvis du skal udenfor - det kan for eksempel være for at gå ud med affald - hvad skal du så have ud over dit arbejdstøj?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Grøn og blå kittel
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `I Pandrup bruger vi blå kitler, mens vi bruger grønne i Avedøre. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Rumdragt
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Gul regnjakke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Almindeligt arbejdstøj
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-lamp-red-light',
		background: 'production',
		image: 'lit-lamp',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvis du er i produktionen og se denne lampe lyse rødt, så er der et problem med ventilationen. Hvad er det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Der er ikke overtryk de rigtige steder`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja. Overtryk sikrer, at der ikke kommer svampe- og skimmelsporer ind i produktionen. `,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `At der ikke kommer ilt ind`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `At der er optagelse i gang`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej, det er vist i radiostudier. `,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `At fabrikken er lukket`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-lamp-red-light-again',
		background: 'production',
		image: 'lit-lamp',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvad skal du gøre hvis overtrykslampen lyser rødt?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ringe til vedligehold/vagten`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Sige det i receptionen`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Ringe efter en ekstern tekniker`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Råbe på hjælp`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-open-gate',
		layout: 's1-m2-open-gate',
		background: 'production',
		backgroundModifier: 'moved-right-no-belt',
		text: {
			da: `Hvad er problemet på det her billede? Klik på fejlen. Klik OK, når du har den, eller hvis du ikke kan finde den. `,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'open-gate',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop! Selv en lille åbning er nok, til at en mus kan komme ind. `,
								en: ``,
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-secure-production',
		background: 'production',
		backgroundModifier: 'moved-right',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Vi skal også sikre vores produktion ved at sørge for, at uvedkommende ikke kommer ind. De har ikke vores træning, og i værste fald kan de aktivt forsøge at sabotere os. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-unaccompanied-guest',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvad skal du altid gøre, hvis du møder en uledsaget gæst?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Spørge, hvem de er, og hvem deres kontaktperson er`,
					en: ``
				},
				isCorrect: true
			}),
			// Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
			// 	id: 2,
			// 	text: {
			// 		da: `Vente til kontaktpersonen kommer`,
			// 		en: ``
			// 	},
			// 	isCorrect: true
			// }),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Byde dem en bolle`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Eskortere dem væk fra matriklen`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Kontakte politiet`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-guests-external-technicians',
		layout: 's1-m2-guests-external-technicians',
		subtype: 'images',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Gæster og eksterne teknikere bærer noget bestemt på hovedet. Hvad er det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Rødt hårnet
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Blå kasket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Sydvest
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Alpehue
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Gult hårnet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-stranger-wants-in',
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvad skal du gøre, hvis du står ved døren, og en du ikke kender gerne vil ind?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Spørge ind til deres kontaktperson`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Vente med gæsten til kontaktperson kommer`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Bare lukke dem ind`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Sende dem til receptionen`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Sige det til nærmeste leder `,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-lastly-four-questions',
		background: 'production',
		backgroundModifier: 'moved-right',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på Schulstad. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme. `,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-1',
		shuffleOptions: false,
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvor enig er du i følgende: 
				<br /><br />Jeg må og skal reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: ``
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-2',
		shuffleOptions: false,
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvor enig er du i følgende:  
				<br /><br />Jeg reagerer hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: ``
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-3',
		shuffleOptions: false,
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvor enig er du i følgende:
				<br /><br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller (og) informerer min nærmeste leder?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: ``
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-4',
		shuffleOptions: false,
		background: 'production',
		backgroundModifier: 'moved-right',
		text: {
			da: `Hvor enig er du i følgende: 
				<br /><br />Hvis jeg oplyser om en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: ``
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-module-end',
		background: 'production',
		backgroundModifier: 'moved-right',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			da: `Sådan, du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med hygiejnetræningen for denne gang. Hvis ikke, så må du tage modulet en gang til. `,
			en: ``,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};