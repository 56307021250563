const moduleImages = [
	'modules/tasks/multiple-choice/s1-m2-three-allergens/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-three-allergens/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-three-allergens/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-three-allergens/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-three-allergens/option-5.svg',

	'modules/tasks/multiple-choice/s1-m2-what-gloves-not-approved/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-what-gloves-not-approved/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-what-gloves-not-approved/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-what-gloves-not-approved/option-4.svg',
	
	'modules/tasks/spot-errors/s1-m2-production-errors/background.svg',
	'modules/tasks/spot-errors/s1-m2-production-errors-2/background.svg',
];

export {
	moduleImages
};