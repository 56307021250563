const moduleImages = [
	'modules/tasks/multiple-choice/s1-m1-hair-snoods/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-snoods/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-snoods/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-snoods/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-snoods/option-5.svg',
	
	'modules/tasks/multiple-choice/s1-m1-hands/background.svg',

	'modules/tasks/multiple-choice/s1-m1-jewellery/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-jewellery/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-jewellery/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-jewellery/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-jewellery/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-jewellery/option-6.svg',
	
	'modules/tasks/multiple-choice/s1-m1-nails/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-5.svg',
	
	'modules/tasks/multiple-choice/s1-m1-production-person/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-production-person/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-production-person/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-production-person/option-4.svg',
	
	'modules/tasks/multiple-choice/s1-m1-scratch-wound/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-scratch-wound/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-scratch-wound/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-scratch-wound/option-4.svg',

	'modules/tasks/spot-errors/s1-m1-dressing-room-locker/background.svg',
	'modules/tasks/spot-errors/s1-m1-worker-issues/background.svg',
];

export {
	moduleImages
};