const backgroundsData = [
	{
		id: 'outside',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			}
		],
	},
	{
		id: 'dressing-room',
		modifiers: [
			{
				id: 'lockers',
			},
			{
				id: 'sole-washer',
			},
			{
				id: 'hygiene-lock'
			},
			{
				id: 'lockers-hygiene-lock'
			},
			{
				id: 'no-bench'
			},
			{
				id: 'zoom-in'
			},
			{
				id: 'zoom-in-no-bench'
			}
		]
	},
	{
		id: 'production',
		modifiers: [
			{
				id: 'none'
			},
			{
				id: 'no-machines'
			},
			{
				id: 'moved-right'
			},
			{
				id: 'moved-right-no-belt'
			}
		]
	}
];

export {backgroundsData};