import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyD_pMToaOmmWKOxinsHyWL8R25Y-fiAE3c',
		authDomain: 'cgl-lantmannen-test.firebaseapp.com',
		projectId: 'cgl-lantmannen-test',
		storageBucket: 'cgl-lantmannen-test.appspot.com',
		messagingSenderId: '367159231035',
		appId: '1:367159231035:web:8746088dfd0278e1b9d21e'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyBtsgOvjcpwjdk_X1Nr0fKRoj3ZiUYbnE4',
		authDomain: 'cgl-lantmannen-production.firebaseapp.com',
		projectId: 'cgl-lantmannen-production',
		storageBucket: 'cgl-lantmannen-production.appspot.com',
		messagingSenderId: '239262750302',
		appId: '1:239262750302:web:d45a579a97096a2be246cf'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;